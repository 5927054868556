.main-header {
    border-bottom:1px solid $gray-200;
    margin-bottom:35px;
    padding-top:45px;
    padding-bottom:35px;
    .home & {
      margin-bottom:0;
    }
}
.admin-bar .fixed-top {
  top: 45px;
  @include media-breakpoint-up(md) {
    top: 32px;
  }
}
.navbar {
  &.navbar-light {
    background: rgba(239, 249, 236, .9);
    padding: 24px 24px 16px;
  }
  .form-control {
    border: 1px solid lighten($light-green, 10);
  }
  .navbar-text {
    font-size: 13px;
  }
  .nav-link {
    font-size: 15px;
  }
  .navbar-toggler {
    border-color: transparent;
    padding: 4px;
    &:active, &:focus {
      outline: none;
    }
  }
}
