.jumbotron {
    padding: 8rem 1.5rem 4.5rem;
    border-radius:0;
    color:#fff;
    text-align: center;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      padding: 9rem 3rem 5rem;
    }
    .container {
      position: relative;
    }
    h1 {
        margin-bottom: .8rem;
        text-shadow: 0 0 5px rgba(#486D5F, .6), 0 1px 1px rgba(#2D4A45, .6);
        font-size: 22px;
        -webkit-font-smoothing: antialiased;
        @include media-breakpoint-up(md) {
          font-size: 38px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 52px;
        }
    }
    .lead {
        margin-bottom: .8rem;
    }
    hr {
    }
    .btn-primary {
      background-image: linear-gradient(to bottom, lighten($primary, 10), $primary);
      box-shadow: 0 1px 3px rgba($black, .16);
      text-shadow: 0 -1px 1px rgba($black, .16);
      font-weight: $font-weight-bold;
      padding-left: 30px;
      padding-right: 30px;
      @include media-breakpoint-up(lg) {
        font-size: 24px;
      }
    }
}
.jumbotron-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @include media-breakpoint-up(xl) {
      filter: blur(1px);
    }
    &:before {
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(../../img/trees.jpg);
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center;
        background-color: $light;
        box-shadow: 0 150px 40px -20px #edf7e8 inset;
    }
}
