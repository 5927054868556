.comment {
    margin-bottom: 35px;
}
.comment-author,
.comment-content {
    margin-left: 70px;
}
.comment-author {
    font-weight: bold;
    margin-bottom: 10px;
}
.comment-form-wrap {
    margin-top: 50px;
    margin-bottom: 50px;
}
.comment-form-wrap {
    h3 {
        margin-bottom:30px;
        border-bottom:1px solid $gray-200;
        padding-bottom:22px;
    }
    .btn {
        margin-top:15px;
    }
    textarea{
        min-height:140px;
    }
}

.gravatar {
    float:left;
    margin-top:5px;
}
