// Required
@import "functions";
@import "variables";
@import "mixins";

// Optional
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "utilities";
@import "print";

// @import "node_modules/bootstrap/scss/bootstrap";

@import 'jumbotron';
@import 'loading';
@import 'header';
@import 'sidebar';
@import 'comments';
@import 'footer';

html {
  background-color: $gray-100;
}
h1, h2, h3, h4, h5, h6, .btn {
  -webkit-font-smoothing: antialiased;
}

.inner {
  padding-top: 90px;
  padding-bottom: 50px;
  @include media-breakpoint-up(md) {
    padding-top: 100px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 120px;
  }
}
.latest-title {
    margin-bottom:25px;
    font-style: italic;
}
.latest-title span{
    font-style:italic;
    font-size:12px;
}
.main-title {
    text-transform: capitalize;
    margin-bottom:15px;
}
.search-wrapper {
    display:flex;
    align-items: center;
    justify-content: center;
}
.single h1.post-title,
.single h2.comments-title{
    margin-bottom:25px;
    font-size: 24px;
    letter-spacing: -.5px;
    padding-bottom:15px;
    border-bottom:1px solid #ebebeb;
    color: $dark-blue;
}
.excerpt p {
  text-overflow: ellipsis;
  overflow: hidden;
}
.the-loop {
  margin:0 0 0 0;
  padding:0 0 0 0;
  li {
      list-style: none;
      margin-bottom: 32px;
  }
  .title {
      font-size:18px;
      // font-family: "raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .meta {
    font-size: 14px;
    font-style: italic;
  }
  .excerpt p {
    font-size: 14px;
  }
}
.meta {
    margin-bottom:5px;
}
.meta,
.meta a {
    color: $gray-600;
}
.fade-in-enter-active {
    transition: all .6s ease;
}
.fade-in-leave-active {
transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-in-enter, .fade-in-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
opacity: 0;
}
.content {
    margin-bottom: 32px;
    font-size: 14px;
    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
    hr {
      clear: both;
    }
}
.content-area img,
.content img {
    max-width:100%;
    height: auto;
    margin-top:5px;
    margin-bottom:15px;
    &.alignleft {
      float: left;
      margin: 0 30px 30px 0;
    }
    &.alignright {
      float: right;
      margin: 0 0 30px 30px;
    }
    &.aligncenter {
      display: block;
      margin: 0 auto 30px;
    }
    &.round {
      border-radius: 50%;
  }
}
.pagination {
    margin-top:30px;
}
.lead {
  font-size: 18px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  &.primary {
      color: $primary;
  }
  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 26px;
  }
}

.icons-row {
  text-align: center;
  font-size: 14px;
  h6  {
    font-size: inherit;
  }
}

.landing-section {
  padding: 48px 0;
  &:nth-child(2n+1) {
    background: darken($light, 3);
  }
}
.landing-section-header {
  font-weight: $font-weight-semibold;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}
.testimonial {
  font-size: 14px;
  small {
    font-size: 11px;
  }
}
.testimonial-author {
  font-style: normal;
  font-weight: $font-weight-bold;
  a {
    color: $body-color;
  }
}
