.footer {
    background:$primary;
    border-top: 1px solid $dark-green;
    color: $white;
    padding-top:48px;
    padding-bottom:48px;
    border-top:1px solid $gray-200;
    font-size: 12px;
    .footer-menu {
      flex-direction: column;
      @include media-breakpoint-up(md) {
        flex-direction: initial;
      }
    }
    .menu-title {
      font-size: inherit;
      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }
    .nav-link {
      color: inherit;
      padding: 4px 0;
      font-size: inherit;
      @include media-breakpoint-up(md) {
        padding-right: 8px ;
      }
    }
    .social{
      .nav-link {
        padding: 4px;
      }
    }
    .copyright {
      margin-top: 40px;
      font-size: inherit;
    }
}
