.sidebar-section {
    padding:18px 20px 12px;
    background-color:$gray-200;
    border-radius:4px;
}
.sidebar-section h3 {
    font-size: 18px;
    margin-left:-20px;
    margin-right:-20px;
    margin-bottom:15px;
    padding-left:20px;
    padding-right:20px;
    padding-bottom:15px;
    border-bottom:1px solid #fff;
}
